export const messages = () => {
    return {
        entitycreatedmasc: '{msg} creado',
        entityupdatedmasc: '{msg} actualizado',
        entitycreatedfem: '{msg} creada',
        entityupdatedfem: '{msg} actualizada',
        moreelements: '(y {msg} más)',
        error: 'Error',
        success: 'Operación exitosa',
        processing_error: 'Error de procesamiento',
        missing_fields: 'Falta completar datos obligatorios',
        has_pending_tickets: 'Tiene seguimientos abiertos',
        onRowDisabledSummary: 'El click derecho sobre las filas está deshabilitado. Pruebe clickear sobre el engranaje',
        onRowDisabledDetail: 'Click derecho deshabilitado',
        error_historial_cotizaciones: 'Error buscando historial de cotizaciones',
        searching: 'Buscando información...',
        codigocreado: 'Código creado',
        filter_required: 'Debe ingresar algún filtro de búsqueda',
        tipo_refa_required: 'Debe ingresar un tipo de Refacturación',
        fin_vigencia_required: 'Debe ingresar una fecha de fin de vigencia',
        background_processing: 'Acción ejecutándose en paralelo. Puede demorar varios minutos.'
    }
}