<script setup>
import { formEnabled } from '@service/FormService';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['cancel-form', 'save-form']);
// formEnabled se setea solo en SaveGenericEntity
// si se usa otra funcion para el save, hay que manejarlo

const props = defineProps({
	hideCancelButton: {
		type: Boolean,
		required: false,
		default: false,
	},
	saveButtonLabel: {
		type: String,
		required: false,
	},
	headerButtons: {
		type: Boolean,
		default: false,
	},
});

const label = props.saveButtonLabel || t('shared.buttons.save');
</script>

<template>
	<div class="w-full max-h-10 p-fluid pt-5 px-2">
		<div v-if="$slots.header" class="flex justify-content-between align-items-center p-0">
			<div class="flex align-items-center justify-content-center">
				<slot name="header" />
			</div>
			<div v-if="headerButtons" class="flex align-items-center justify-content-center">
				<div class="p-fluid-grid flex pr-3">
					<div class="col-12">
						<span class="p-float-label flex flex-row-reverse">
							<Button
								v-if="!hideCancelButton"
								class="w-2 max-w-15rem min-w-min pl-2 pr-2 pt-1 pb-1 p-button-cancel"
								:label="t('shared.buttons.cancel')"
								@click="$emit('cancel-form', $event)"
							/>
							<Button
								class="w-2 max-w-15rem min-w-min pl-2 pr-2 pt-1 pb-1 mr-1 p-button-success"
								type="submit"
								:label="label"
								@click="$emit('save-form', $event)"
							/>
						</span>
					</div>
				</div>
			</div>
		</div>
		<form @submit.prevent="$emit('save-form', $event)" @keydown.enter.prevent>
			<BlockUI :blocked="!formEnabled" fullScreen>
				<slot />
				<div class="p-fluid grid flex pr-3">
					<div class="field col-12 mt-5 flex">
						<span class="flex">
							<slot name="bottom_left_buttons" />
						</span>
						<span class="flex-grow-1" />
						<span class="flex flex-row-reverse">
							<Button
								v-if="!hideCancelButton"
								class="w-2 max-w-15rem min-w-min p-button-cancel"
								:label="t('shared.buttons.cancel')"
								@click="$emit('cancel-form', $event)"
							/>
							<Button class="w-2 max-w-15rem min-w-min mr-2 p-button-success" type="submit" :label="label" />
						</span>
					</div>
				</div>
			</BlockUI>
		</form>
	</div>
</template>

<style scoped>
.max-h-10 {
	max-height: 83.3333vh;
}
</style>
