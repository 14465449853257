export const productores = () => {
	return {
		title: 'Productores Matriculados',
		contextMenu: {
			edit: 'Ver / Editar Productor',
			delete: 'Borrar Productor',
            set_as_principal: 'Marcar como Productor predeterminado'
		},
		toast: {
			success: {
				user: {
					updated: 'Usuario actualizado',
					created: 'Usuario creado',
				},
				producer: {
					updated: 'Productor actualizado',
					created: 'Productor creado',
				},
			},
			error: {
				summary: 'Error',
				producers: 'Error buscando productores',
			},
		},
		confirm: {
			create: 'Se creará un usuario Productor en Mi Sociedad, con acceso a todos mis datos. IMPORTANTE: este usuario tendrá un costo que se sumará a su plan actual. ¿Confirmar?',
			delete: '¿Está seguro que desea borrar este productor?',
            set_as_principal: 'Marcar productor como principal/predeterminado. ¿Confirmar?'
		},
		searchSideBar: {
			header: 'Búsqueda de Productores Matriculados',
		},
		dialog: {
			editProducer: 'Editar Productor {producer}',
			newProducer: 'Nuevo Productor Propio - Tiene acceso a mis datos',
		},
		saveCancelForm: {
			email: 'Email (aqui recibirá las instrucciones de acceso)',
		},
		datatable: {
			column: 'Productor',
			tab: {
				withAccess: 'Con acceso al sistema',
				withoutAccess: 'Sin acceso al sistema',
				external: 'Externo',
			},
		},
		producerCodesTable: '{producer} - Códigos / Comisiones predefinidas'
	};
};
