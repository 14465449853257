import { i18nizer } from '@service/i18nService';

export const tipos_uso_automotor = [
	'particular',
	'comercial',
	'comercial_carga_peligrosa',
	'comercial_servicio_especial',
].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_uso_automotor', type),
}));

export const tipos_carroceria_automotor = [
	'cuatro_por_dos',
	'cuatro_por_cuatro',
	'abierta',
	'ambulancia',
	'baranda',
	'capota',
	'cupula',
	'grua',
	'camion',
	'camion_bacheador',
	'camon_barandas',
	'camion_caja_cerealera',
	'camion_carrozado',
	'camion_con_caja',
	'camion_furg_termico',
	'camion_grua',
	'camion_mezclador',
	'camion_plataforma',
	'camion_playo',
	'camion_regador',
	'camion_tractor',
	'camion_volcador',
	'camion_balancin',
	'carrozada',
	'cerrada',
	'convertible',
	'coupe',
	'cuatriciclo',
	'chasis_cabina',
	'doble_cabina',
	'furgon',
	'furgon_de_fabrica',
	'furgon_termico',
	'jeep_cam_fam',
	'minibus',
	'motocicleta',
	'pick_up_clase_a',
	'pick_up_clase_b',
	'pick_up_cab_y_media',
	'pick_up_carrozada',
	'playa',
	'portacorona',
	'rural',
	'sedan',
	'semi_remolque',
	'tractor_carretera',
	'trailer',
	'microbus',
	'casa_rodante',
	'carreton',
	'camion_menor_10_tn',
	'camion_mayor_10_tn',
	'bus',
	'bantam',
	'autoelevador',
	'acoplado',
	'jeep',
    'coleccion',
    'portavolquete',
    'ciclomotor'
].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_carroceria_automotor', type),
}));

export const tipos_vehiculo = ['auto', 'moto', 'camion', 'maquinaria', 'remolque'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_vehiculo', type),
}));

export const tipos_origen_vehiculo = ['nacional', 'importado'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_origen_vehiculo', type),
}));

export const tipos_documento = ['dni', 'cuit', 'ci', 'pasaporte', 'lc', 'le', 'rut'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_documento', type),
}));

export function getCarLogo(car) {
	const found_car = car_logos.find((cl) => cl.brand == car.brand);
	return found_car ? '/images/autos_logos/' + found_car.thumb : null;
}

export const car_logos = [
	{ brand: 'ALFA ROMEO', thumb: 'alfa-24-h.png' },
	{ brand: 'FORD', thumb: 'ford-24-h.png' },
	{ brand: 'AUDI', thumb: 'audi-24-h.png' },
	{ brand: 'CHEVROLET', thumb: 'chevrolet-24-h.png' },
	{ brand: 'FIAT', thumb: 'fiat-24-h.png' },
	{ brand: 'RENAULT', thumb: 'renault-24-h.png' },
	{ brand: 'BMW', thumb: 'bmw-24-h.png' },
	{ brand: 'MERCEDES BENZ', thumb: 'mercedes-24-h.png' },
	{ brand: 'MERCEDES BENZ C', thumb: 'mercedes-24-h.png' },
	{ brand: 'CHERY', thumb: 'chery-24-h.png' },
	{ brand: 'CITROEN', thumb: 'citroen-24-h.png' },
	{ brand: 'CHRYSLER', thumb: 'chrysler-24-h.png' },
	{ brand: 'HONDA', thumb: 'honda-24-h.png' },
	{ brand: 'HONDA MOTOS', thumb: 'honda-24-h.png' },
	{ brand: 'KIA', thumb: 'kia-24-h.png' },
	{ brand: 'KIA CAMION', thumb: 'kia-24-h.png' },
	{ brand: 'HYUNDAI', thumb: 'hyundai-24-h.png' },
	{ brand: 'NISSAN', thumb: 'nissan-24-h.png' },
	{ brand: 'PEUGEOT', thumb: 'peugeot-24-h.png' },
	{ brand: 'TOYOTA', thumb: 'toyota-24-h.png' },
	{ brand: 'VOLKSWAGEN', thumb: 'vw-24-h.png' },
	{ brand: 'VOLKSWAGEN CAM.', thumb: 'vw-24-h.png' },
	{ brand: 'YAMAHA', thumb: 'yamaha-24-h.png' },
	{ brand: 'IVECO', thumb: 'iveco-24-h.png' },
];

export function useTipoDocTranslation(tipo_doc) {
	let tDoc = tipos_documento.find((x) => x.id === tipo_doc);
	return tDoc ? tDoc.value : '';
}

export const tipos_operacion = [
	'nuevo_seguro',
	'renovacion',
	'endorsement',
	'anulacion',
	'modificacion_registro',
	'anulacion_registro',
].map((type) => ({
	codigo: type,
	descripcion: i18nizer('shared.entities.tipos_operacion', type),
}));

export const getTipoOperacionLabel = (value) => {
	return tipos_operacion.find((el) => el.codigo === value).descripcion;
};

export const tipos_banco = [
	{ value: 'AMERICAN EXPRESS BANK LTD' },
	{ value: 'BACS BANCO DE CREDITO Y SECURITIZACION' },
	{ value: 'BANCO BICA S.A.' },
	{ value: 'BANCO BRADESCO ARGENTINA S.A.' },
	{ value: 'BANCO CETELEM ARGENTINA S.A.' },
	{ value: 'BANCO CMF S.A.' },
	{ value: 'BANCO COLUMBIA S.A.' },
	{ value: 'BANCO COMAFI SOCIEDAD ANONIMA' },
	{ value: 'BANCO CREDICOOP COOPERATIVO LIMITADO' },
	{ value: 'BANCO DE CORRIENTES S.A.' },
	{ value: 'BANCO DE FORMOSA S.A.' },
	{ value: 'BANCO DE GALICIA Y BUENOS AIRES S.A.' },
	{ value: 'BANCO DE INVERSION Y COMERCIO EXTERIOR' },
	{ value: 'BANCO DE LA CIUDAD DE BUENOS AIRES' },
	{ value: 'BANCO DE LA NACION ARGENTINA' },
	{ value: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOM&Iacute;A' },
	{ value: 'BANCO DE LA PROVINCIA DE BUENOS AIRES' },
	{ value: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.' },
	{ value: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY' },
	{ value: 'BANCO DE SAN JUAN S.A.' },
	{ value: 'BANCO DE SANTA CRUZ S.A.' },
	{ value: 'BANCO DE SANTIAGO DEL ESTERO S.A.' },
	{ value: 'BANCO DE SERVICIOS FINANCIEROS S.A.' },
	{ value: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.' },
	{ value: 'BANCO DE VALORES S.A.' },
	{ value: 'BANCO DEL CHUBUT S.A.' },
	{ value: 'BANCO DEL SOL S.A.' },
	{ value: 'BANCO DEL TUCUMAN S.A.' },
	{ value: 'BANCO DO BRASIL S.A.' },
	{ value: 'BANCO FINANSUR S.A.' },
	{ value: 'BANCO HIPOTECARIO S.A.' },
	{ value: 'BANCO INDUSTRIAL S.A.' },
	{ value: 'BANCO INTERFINANZAS S.A.' },
	{ value: 'BANCO ITAU ARGENTINA S.A.' },
	{ value: 'BANCO JULIO SOCIEDAD ANONIMA' },
	{ value: 'BANCO MACRO S.A.' },
	{ value: 'BANCO MARIVA S.A.' },
	{ value: 'BANCO MASVENTAS S.A.' },
	{ value: 'BANCO MERIDIAN S.A.' },
	{ value: 'BANCO MUNICIPAL DE ROSARIO' },
	{ value: 'BANCO PATAGONIA S.A.' },
	{ value: 'BANCO PIANO S.A.' },
	{ value: 'BANCO PRIVADO DE INVERSIONES S.A.' },
	{ value: 'BANCO PROVINCIA DE TIERRA DEL FUEGO' },
	{ value: 'BANCO PROVINCIA DEL NEUQU&Eacute;N S.A.' },
	{ value: 'BANCO ROELA S.A.' },
	{ value: 'BANCO SAENZ S.A.' },
	{ value: 'BANCO SANTANDER RIO S.A.' },
	{ value: 'BANCO SUPERVIELLE S.A.' },
	{ value: 'BANK OF AMERICANATIONAL ASSOCIATION' },
	{ value: 'BBVA BANCO FRANCES S.A.' },
	{ value: 'BNP PARIBAS' },
	{ value: "CAJA DE CREDITO 'CUENCA' COOPERATIVA LIMITADA" },
	{ value: 'CAJA DE CREDITO COOPERATIVA LA CAPITAL' },
	{ value: 'CITIBANK N.A.' },
	{ value: 'COMPA&Ntilde;IA FINANCIERA ARGENTINA S.A.' },
	{ value: 'CORDIAL COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'DEUTSCHE BANK S.A.' },
	{ value: 'FIAT CREDITO COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'FINANDINO COMPA&Ntilde;&Iacute;A FINANCIERA S.A.' },
	{ value: 'FORD CREDIT COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'GPAT COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'HSBC BANK ARGENTINA S.A.' },
	{ value: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA' },
	{ value: 'JOHN DEERE CREDIT COMPA&Ntilde;&Iacute;A FINANCIERA S.' },
	{ value: 'JPMORGAN CHASE BANKNATIONAL ASSOCIATION' },
	{ value: 'MBA LAZARD BANCO DE INVERSIONES S. A.' },
	{ value: 'MERCEDES-BENZ COMPA&Ntilde;IA FINANCIERA ARGENTINA' },
	{ value: 'METROPOLIS COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'MONTEMAR COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'MULTIFINANZAS COMPA&Ntilde;IA FINANCIERA S.A.' },
	{ value: 'NUEVO BANCO DE ENTRE R&Iacute;OS S.A.' },
	{ value: 'NUEVO BANCO DE LA RIOJA SOCIEDAD ANONIMA' },
	{ value: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA' },
	{ value: 'NUEVO BANCO DEL CHACO S. A.' },
	{ value: 'PSA FINANCE ARGENTINA COMPA&Ntilde;&Iacute;A FINANCIER' },
	{ value: 'RCI BANQUE S.A.' },
	{ value: 'ROMBO COMPA&Ntilde;&Iacute;A FINANCIERA S.A.' },
	{ value: 'THE BANK OF TOKYO-MITSUBISHI UFJLTD.' },
	{ value: 'THE ROYAL BANK OF SCOTLAND N.V.' },
	{ value: 'TOYOTA COMPA&Ntilde;&Iacute;A FINANCIERA DE ARGENTINA' },
	{ value: 'VOLKSWAGEN CREDIT COMPA&Ntilde;&Iacute;A FINANCIERA S.' },
];

export const tipos_tarjeta = [
	{ value: 'American Express' },
	{ value: 'Aura' },
	{ value: 'Aurus Visa' },
	{ value: 'Azul (ex Bisel)' },
	{ value: 'Cabal' },
	{ value: 'CMR Falabella' },
	{ value: 'Carta Credencial' },
	{ value: 'Carta Franca' },
	{ value: 'Confiable' },
	{ value: 'Consumax' },
	{ value: 'Cordobesa' },
	{ value: 'Credial' },
	{ value: 'CREDIMAS' },
	{ value: 'Diners' },
	{ value: 'Eficaz' },
	{ value: 'Favacard' },
	{ value: 'Italcred' },
	{ value: 'Kadicard' },
	{ value: 'Mastercard' },
	{ value: 'Nativa' },
	{ value: 'Nevada' },
	{ value: 'Nexo' },
	{ value: 'Patagonia 365' },
	{ value: 'Tarjeta Coto Inteligente' },
	{ value: 'Tarjeta Mas' },
	{ value: 'Tarjeta Naranja' },
	{ value: 'Tarjeta Shopping' },
	{ value: 'Tarjeta Sol' },
	{ value: 'Visa' },
];

export const tipos_profesion = [
	'abogado',
	'academico',
	'adjunto',
	'administrador',
	'agronomo',
	'alergista',
	'alergologa',
	'almacenero',
	'anatomista',
	'anestesiologo',
	'antologista',
	'antropologo',
	'arabista',
	'archivero',
	'arqueologo',
	'arquitecto',
	'asesor',
	'asistente',
	'astrofisico',
	'astrologo',
	'astronomo',
	'atleta',
	'ats',
	'autor',
	'auxiliar',
	'avicultor',
	'bacteriologo',
	'bedel',
	'bibliografo',
	'bibliotecario',
	'biofisico',
	'biografo',
	'biologo',
	'bioquimico',
	'botanico',
	'cancerologo',
	'cardiologo',
	'cartografo',
	'castrador',
	'catedratico',
	'cirujano',
	'citologo',
	'climatologo',
	'codirector',
	'comadron',
	'consejero',
	'conserje',
	'conservador',
	'coordinador',
	'cosmografo',
	'cosmologo',
	'criminalista',
	'cronologo',
	'decano',
	'decorador',
	'defensor',
	'delegado',
	'delineante',
	'demografo',
	'dentista',
	'dermatologo',
	'dibujante',
	'directivo',
	'director',
	'dirigente',
	'doctor',
	'documentalista',
	'ecologo',
	'economista',
	'educador',
	'egiptologo',
	'endocrinologo',
	'enfermero',
	'enologo',
	'entomologo',
	'epidemiologo',
	'especialista',
	'espeleologo',
	'estadista',
	'estadistico',
	'etimologo',
	'etnografo',
	'etnologo',
	'etologo',
	'examinador',
	'facultativo',
	'farmaceutico',
	'farmacologo',
	'filologo',
	'filosofo',
	'fiscal',
	'fisico',
	'fisiologo',
	'fisioterapeuta',
	'fonetista',
	'foniatra',
	'fonologo',
	'forense',
	'fotografo',
	'funcionario',
	'gemologo',
	'genetista',
	'geobotanica',
	'geodesta',
	'geofisico',
	'geografo',
	'geologo',
	'geomantico',
	'geoquimica',
	'gerente',
	'geriatra',
	'gerontologo',
	'gestor',
	'grabador',
	'graduado',
	'grafologo',
	'gramatico',
	'hematologo',
	'hepatologo',
	'hidrogeologo',
	'hidrografo',
	'hidrologo',
	'higienista',
	'hispanista',
	'historiador',
	'homeopata',
	'informatico',
	'ingeniero',
	'inmunologo',
	'inspector',
	'interino',
	'interventor',
	'investigador',
	'jardinero',
	'jefe',
	'jubilado',
	'juez',
	'latinista',
	'lector',
	'letrado',
	'lexicografo',
	'lexicologo',
	'licenciado',
	'linguista',
	'logopeda',
	'maestro',
	'matematico',
	'matron',
	'medico',
	'meteorologo',
	'micologo',
	'microbiologico',
	'microcirujano',
	'mimografo',
	'mineralogista',
	'monitor',
	'musicologo',
	'naturopata',
	'nefrologo',
	'neumologo',
	'neuroanatomista',
	'neurobiologo',
	'neurocirujano',
	'neuroembriologo',
	'neurofisiologo',
	'neurologo',
	'nutrologo',
	'oceanografo',
	'odontologo',
	'oficial',
	'oficinista',
	'oftalmologo',
	'oncologo',
	'optico',
	'optometrista',
	'ordenanza',
	'orientador',
	'ornitologo',
	'ortopedico',
	'ortopedista',
	'osteologo',
	'osteopata',
	'otorrinolaringologo',
	'paleobiologo',
	'paleobotanico',
	'paleografo',
	'paleologo',
	'paleontologo',
	'patologo',
	'pedagogo',
	'pediatra',
	'pedicuro',
	'periodista',
	'tecnico',
	'piscicultor',
	'podologo',
	'portero',
	'prehistoriador',
	'presidente',
	'proctologo',
	'profesor',
	'programador',
	'protesico',
	'proveedor',
	'psicoanalista',
	'psicologo',
	'psicofisico',
	'psicopedagogo',
	'psicoterapeuta',
	'psiquiatra',
	'publicista',
	'publicitario',
	'puericultor',
	'quimico',
	'quiropractico',
	'radioastronomo',
	'radiofonista',
	'radiologo',
	'radiotecnico',
	'radiotelefonista',
	'radiotelegrafista',
	'radioterapeuta',
	'rector',
	'retirado',
	'sanitario',
	'secretario',
	'sexologo',
	'sismologo',
	'sociologo',
	'subdelegado',
	'subdirector',
	'subsecretario',
	'tecnico',
	'telefonista',
	'teologo',
	'terapeuta',
	'tocoginecologo',
	'tocologo',
	'toxicologo',
	'traductor',
	'transcriptor',
	'traumatologo',
	'tutor',
	'urologo',
	'veterinario',
	'vicedecano',
	'vicedirector',
	'vicegerente',
	'vicepresidente',
	'vicerrector',
	'vicesecretario',
	'virologo',
	'viticultor',
	'vulcanologo',
	'xilografo',
	'zoologo',
	'zootecnico',
	'comerciante',
	'empleado',
	'contador',
].map((type) => ({
	id: i18nizer('shared.entities.tipos_profesion', type),
	descripcion: i18nizer('shared.entities.tipos_profesion', type),
}));

export const tipos_estado_civil = ['soltero', 'casado', 'divorciado', 'viudo', 'separado', 'conviviente'].map(
	(type) => ({
		id: type,
		value: i18nizer('shared.entities.tipos_estado_civil', type),
	})
);

export const tipos_direccion = ['personal', 'laboral', 'cobranza', 'correspondencia'].map((type) => ({
	codigo: type,
	descripcion: i18nizer('shared.entities.tipos_direccion', type),
	icon:
		type == 'personal'
			? 'pi-user'
			: type == 'laboral'
			? 'pi-building'
			: type == 'cobranza'
			? 'pi-dollar'
			: 'pi-envelope',
}));

// dejo paises para después
export const paises = [
	{value: 'Aruba'},
    {value: 'Argentina'},
    {value: 'Antártida'},
    {value: 'Australia'},
    {value: 'Austria'},
    {value: 'Bélgica'},
    {value: 'Bangladesh'},
    {value: 'Bulgaria'},
    {value: 'Bahamas'},
    {value: 'Belice'},
    {value: 'Bermudas'},
    {value: 'Bolivia'},
    {value: 'Brasil'},
    {value: 'Canadá'},
    {value: 'Islas Cocos'},
    {value: 'Suiza'},
    {value: 'Chile'},
    {value: 'China'},
    {value: 'Colombia'},
    {value: 'Costa Rica'},
    {value: 'Cuba'},
    {value: 'República Checa'},
    {value: 'Alemania'},
    {value: 'Domínica'},
    {value: 'Dinamarca'},
    {value: 'República Dominicana'},
    {value: 'Ecuador'},
    {value: 'Egipto'},
    {value: 'España'},
    {value: 'Finlandia'},
    {value: 'Fiji'},
    {value: 'Francia'},
    {value: 'Gabón'},
    {value: 'Reino Unido'},
    {value: 'Georgia'},
    {value: 'Grecia'},
    {value: 'Guatemala'},
    {value: 'Honduras'},
    {value: 'India'},
    {value: 'Israel'},
    {value: 'Italia'},
    {value: 'Jamaica'},
    {value: 'Japón'},
    {value: 'México'},
    {value: 'Países Bajos'},
    {value: 'Noruega'},
    {value: 'Nueva Zelanda'},
    {value: 'Perú'},
    {value: 'Puerto Rico'},
    {value: 'Portugal'},
    {value: 'Paraguay'},
    {value: 'El Salvador'},
    {value: 'San Marino'},
    {value: 'Suecia'},
    {value: 'Seychelles'},
    {value: 'Tailandia'},
    {value: 'Trinidad y Tobago'},
    {value: 'Uruguay'},
    {value: 'Estados Unidos de América'},
    {value: 'Venezuela'},
    {value: 'Vietnam'}
];

export function useTipoDireccion(tipo_dir) {
	let tDir = tipos_direccion.find((x) => x.codigo === tipo_dir);
	return tDir ?? { icon: 'pi-map-marker' };
}

// export const tipos_iva = [
// 	{
// 		id: 'monotributista',
// 		value: 'Monotributista',
// 	},
// 	{
// 		id: 'responsable_inscripto',
// 		value: 'Responsable Inscripto',
// 	},
// 	{
// 		id: 'exento',
// 		value: 'Exento',
// 	},
// 	{
// 		id: 'consumidor_final',
// 		value: 'Consumidor final',
// 	},
// 	{
// 		id: 'no_alcanzado',
// 		value: 'No alcanzado',
// 	},
// 	{
// 		id: 'gran_contribuyente',
// 		value: 'Gran Contribuyente',
// 	},
// ];

export const tipos_iva = [
	'monotributista',
	'responsable_inscripto',
	'exento',
	'consumidor_final',
	'no_alcanzado',
	'gran_contribuyente',
].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_iva', type),
}));

export function useTipoIvaTranslation(tipo_iva) {
	let tIva = tipos_iva.find((x) => x.id === tipo_iva);
	return tIva ? tIva.value : '';
}

export const tipos_persona = ['fisica', 'juridica', 'consorcio'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_persona', type),
}));

export const tipos_sexo = ['hombre', 'mujer'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_sexo', type),
}));

export const tipos_telefono = [{ id: 'Personal' }, { id: 'Laboral' }, { id: 'Celular' }, { id: 'Whatsapp' }];

export const tipos_mail = [{ id: 'Personal' }, { id: 'Laboral' }, { id: 'Alternativo' }, { id: 'Cobranza' }];

export function dummyTranslator(value) {
	return value;
}

// export const tomador_asegurado_options = [
// 	{ id: 'tomador', value: 'Tomador' },
// 	{ id: 'asegurado', value: 'Asegurado' },
// ];

export const tomador_asegurado_options = ['tomador', 'asegurado'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tomador_asegurado_options', type),
}));

export const potencial_captivo_options = ['potencial', 'captivo', 'never_client'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.potencial_captivo_options', type),
}));

// dejo tipo_estado_cir para después
export const tipo_estado_cir = [
	{ external_id: 'pedido_en_adminse', severity: 'error', value: 'Solicitud de alta realizada en AdminSE' },
	{
		external_id: 'pedido_datos_usuario',
		severity: 'error',
		value: 'Datos pendientes de envío a Integraciones',
	},
	{ external_id: 'pedido_en_cia', severity: 'error', value: 'Solicitud de alta en compañía realizada' },
	{ external_id: 'configurado', severity: 'success', value: 'Configurado correctamente' },
	{ external_id: 'error_configuracion', severity: 'error', value: 'Error en configuración' },
	{
		external_id: 'configurado_coti_falta_nove',
		severity: 'success',
		value: 'Cotizador configurado. Importacion pendiente.',
	},
	{
		external_id: 'configurado_nove_falta_coti',
		severity: 'success',
		value: 'Importación configurado. Cotizador pendiente.',
	},
	{
		external_id: 'pedido_en_cia_por_usuario',
		severity: 'error',
		value: 'Solicitud de alta en compañía realizada por el Productor',
	},
	{ external_id: 'anulado_no_contrato', severity: 'error', value: 'Anulado por no contratar' },
];

export const tipos_accion = ['update', 'create', 'destroy', 'unify', 'freeze', 'unfreeze', 'update_permissions', 'inactivate'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_accion', type),
}));

export const tipos_entidad = [
	'policy',
	'person',
	'ticket',
	'siniestro',
	'endorsement',
	'originalpolicy',
	'companyoperationcode',
].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipos_entidad', type),
}));

export const tipo_origen_cheque = ['propio', 'ajeno'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipo_origen_cheque', type),
}));

export const tipo_estado_cheque = ['cartera', 'aplicado', 'rechazado'].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipo_estado_cheque', type),
}));

export const tipo_seguimiento = [
	'reminder',
	'llamado',
	'sell_oportunity',
	'siniestro',
	'otro',
	'visita',
	'envio_doc',
	'gestion_cobranza',
	'pedido_info',
    'reunion'
].map((type) => ({
	id: type,
	value: i18nizer('shared.entities.tipo_seguimiento', type),
}));

export const usuario_o_externo = [
	{ id: 'usuario', value: 'Usuario' },
	{ id: 'externo', value: 'Externo' },
];

export const cliente_poliza_o_siniestro = [
	{ id: 'person', value: 'Cliente / Prospecto' },
	{ id: 'original_policy', value: 'Póliza' },
	{ id: 'siniestro', value: 'Siniestro' },
];

export const tipos_estado_poliza = [
	{
		id: 'solicitud',
		value: 'Solicitud/Propuesta',
	},
	{
		id: 'vigente',
		value: 'Vigente - Nueva',
	},
	{
		id: 'renovacion',
		value: 'Vigente - Renovación',
	},
    {
		id: 'solicitud_renovacion',
		value: 'Solicitud de Renovación',
	},
	{
		id: 'expirada',
		value: 'Vencida',
	},
	{
		id: 'renovada',
		value: 'Renovada',
	},
	{
		id: 'anulada',
		value: 'Anulada',
	},
	{
		id: 'renovada_vigente',
		value: 'Renovada (aún vigente)',
	},
	{
		id: 'renovacion_a_futuro',
		value: 'Emitida (aún NO vigente)',
	},
    {
		id: 'import_auxiliar',
		value: 'Auxiliar de importación',
	},
];

export const tipos_estado_poliza_simplificados = [
	{
		label: 'Todas',
		value: [
			'solicitud',
			'vigente',
			'renovacion',
			'expirada',
			'renovada',
			'anulada',
			'renovada_vigente',
            'solicitud_renovacion',
            'import_auxiliar',
			'renovacion_a_futuro',
		],
	},
	{
		label: 'Todas (excepto anuladas)',
		value: [
			'solicitud',
			'vigente',
			'renovacion',
			'expirada',
			'renovada',
			'renovada_vigente',
            'solicitud_renovacion',
            'import_auxiliar',
			'renovacion_a_futuro',
		],
	},
	{
		label: 'Sólo vigentes',
		value: ['solicitud', 'vigente', 'renovacion','solicitud_renovacion'],
	},
	{
		label: 'Todas las NO renovadas',
		value: ['solicitud', 'vigente', 'renovacion', 'expirada'],
	},
	{
		label: 'Todas las NO renovadas (incluidas las anuladas)',
		value: ['solicitud', 'vigente', 'renovacion', 'expirada', 'anulada'],
	},
];

export const tipos_despacho_poliza = [
	{
		id: 'pendiente',
		value: 'Pendiente',
	},
	{
		id: 'en_mano',
		value: 'En mano',
	},
	{
		id: 'correo_postal',
		value: 'Correo postal',
	},
	{
		id: 'mail',
		value: 'Mail',
	},
	{
		id: 'whatsapp',
		value: 'Whatsapp',
	},
];

export const tipo_estado_siniestro = [
	{
		id: 'tramite',
		value: 'En trámite',
		status: 'abierto',
	},
    {
		id: 'juicio_mediacion',
		value: 'Juicio Mediacion',
		status: 'abierto',
	},
    {
		id: 'reclamo_tercero',
		value: 'Reclamo a tercero',
		status: 'abierto',
	},
    {
		id: 'suspension_plazos',
		value: 'Suspensión de plazos',
		status: 'cerrado',
	},
    {
		id: 'denunciado_cia',
		value: 'Denunciado a la compañía',
		status: 'abierto',
	},
	{
		id: 'denunciado_preventivamente',
		value: 'Denunciado preventivamente',
		status: 'abierto',
	},
    {
		id: 'chatbot',
		value: 'Iniciado por Chatbot',
		status: 'abierto',
	},
    {
		id: 'reaperturado',
		value: 'Reabierto',
		status: 'abierto',
	},
    {
		id: 'declinado',
		value: 'Declinado',
		status: 'cerrado',
	},
	{
		id: 'resolucion_favorable_asegurado',
		value: 'Resolucion favorable al asegurado',
		status: 'cerrado',
	},
	{
		id: 'resolucion_desfavorable_asegurado',
		value: 'Resolucion desfavorable al asegurado',
		status: 'cerrado',
	},
	{
		id: 'gestiona_cliente',
		value: 'Gestiona el Cliente',
		status: 'cerrado',
	},
	{
		id: 'cobrado',
		value: 'Cobrado',
		status: 'cerrado',
	},
	{
		id: 'pagado',
		value: 'Pagado',
		status: 'cerrado',
	},
	{
		id: 'prescrito',
		value: 'Prescrito',
		status: 'cerrado',
	},
	{
		id: 'cerrado',
		value: 'Cerrado',
		status: 'cerrado',
	},
    {
        id: 'rechazado_cia',
        value: 'Rechazado en Compañía',
        status: 'cerrado',
    },

];

export function useTipoEstadoSiniestroTranslation(tipo_est) {
	return tipo_estado_siniestro.find((x) => x.id === tipo_est);
}

export const tipo_motivo_siniestro = [
	{ id: 'ampliacion_denuncia', value: 'AMPLIACION/CORRECCION DENUNCIA' },
	{ id: 'consulta_cobertura', value: 'CONSULTA COBERTURA' },
	{ id: 'consulta_liquidacion', value: 'CONSULTA LIQUIDACION DE SINIESTRO' },
	{ id: 'consulta_caracter_tecnico', value: 'CONSULTA/PEDIDO CARÁCTER TECNICO/OPERATIVO' },
	{ id: 'deriva_cia', value: 'DERIVA CIA' },
	{ id: 'emision_orden_reposicion', value: 'EMISIÓN ORDEN DE REPOSICION' },
	{ id: 'gestion_indemnizatoria', value: 'GESTIÓN INDEMNIZATORIA' },
	{ id: 'gestion_reintegro', value: 'GESTIÓN POR REINTEGRO' },
	{ id: 'informacion_dt', value: 'INFORMACION DT' },
	{ id: 'informacion_rt', value: 'INFORMACION RT' },
	{ id: 'pedido_alta_denuncia', value: 'PEDIDO ALTA DE DENUNCIA' },
	{ id: 'pedido_doc', value: 'PEDIDO DE DOC' },
	{ id: 'recepcion_cd', value: 'RECEPCION DE CD' },
    { id: 'inspeccion', value: 'INSPECCION' },
    { id: 'danios_parciales', value: 'DAÑOS PARCIALES' },
    { id: 'robo_parcial', value: 'ROBO PARCIAL' },
    { id: 'pasivo', value: 'PASIVO' },
];

export function useTipoMotivoSiniestroTranslation(tipo_mot) {
	let tMot = tipo_motivo_siniestro.find((x) => x.id === tipo_mot);
	return tMot ? tMot.value : '';
}

export const tipo_activos_reportes_unidades = [
	{ label: 'Todas', value: 'all' },
	{ label: 'Sólo Activos', value: 'active' },
	{ label: 'Sólo No Activos', value: 'inactive' },
	{ label: 'Con historial de cambios', value: 'history' },
];

export const paises_viajes = [
	{
		id: 4,
		nombre: 'Afganistan',
		codigo: 'AF',
	},
	{
		id: 5,
		nombre: 'Albania',
		codigo: 'AL',
	},
	{
		id: 6,
		nombre: 'Alemania',
		codigo: 'DE',
	},
	{
		id: 7,
		nombre: 'Andorra',
		codigo: 'AD',
	},
	{
		id: 8,
		nombre: 'Angola',
		codigo: 'AO',
	},
	{
		id: 9,
		nombre: 'Anguilla',
		codigo: 'AI',
	},
	{
		id: 10,
		nombre: 'Antigua y Barbuda',
		codigo: 'AG',
	},
	{
		id: 11,
		nombre: 'Antillas Holandesas',
		codigo: 'AN',
	},
	{
		id: 12,
		nombre: 'Arabia Saudi',
		codigo: 'SA',
	},
	{
		id: 13,
		nombre: 'Argelia',
		codigo: 'DZ',
	},
	{
		id: 1,
		nombre: 'Argentina',
		codigo: 'AR',
	},
	{
		id: 14,
		nombre: 'Armenia',
		codigo: 'AM',
	},
	{
		id: 15,
		nombre: 'Aruba',
		codigo: 'AW',
	},
	{
		id: 16,
		nombre: 'Australia',
		codigo: 'AU',
	},
	{
		id: 17,
		nombre: 'Austria',
		codigo: 'AT',
	},
	{
		id: 18,
		nombre: 'Azerbaiyan',
		codigo: 'AZ',
	},
	{
		id: 19,
		nombre: 'Bahamas',
		codigo: 'BS',
	},
	{
		id: 20,
		nombre: 'Bahrein',
		codigo: 'BH',
	},
	{
		id: 21,
		nombre: 'Bangladesh',
		codigo: 'BD',
	},
	{
		id: 22,
		nombre: 'Barbados',
		codigo: 'BB',
	},
	{
		id: 23,
		nombre: 'Bélgica',
		codigo: 'BE',
	},
	{
		id: 24,
		nombre: 'Belize',
		codigo: 'BZ',
	},
	{
		id: 25,
		nombre: 'Benin',
		codigo: 'BJ',
	},
	{
		id: 26,
		nombre: 'Bhutan',
		codigo: 'BT',
	},
	{
		id: 27,
		nombre: 'Bielorrusia',
		codigo: 'BY',
	},
	{
		id: 28,
		nombre: 'Bolivia',
		codigo: 'BO',
	},
	{
		id: 29,
		nombre: 'Bosnia y Herzegovina',
		codigo: 'BA',
	},
	{
		id: 30,
		nombre: 'Botswana',
		codigo: 'BW',
	},
	{
		id: 31,
		nombre: 'Brasil',
		codigo: 'BR',
	},
	{
		id: 32,
		nombre: 'Brunei Darussalam',
		codigo: 'BN',
	},
	{
		id: 33,
		nombre: 'Bulgaria',
		codigo: 'BG',
	},
	{
		id: 34,
		nombre: 'Burkina Faso',
		codigo: 'BF',
	},
	{
		id: 35,
		nombre: 'Burundi',
		codigo: 'BI',
	},
	{
		id: 36,
		nombre: 'Caimanes',
		codigo: 'KY',
	},
	{
		id: 37,
		nombre: 'Camboya',
		codigo: 'KH',
	},
	{
		id: 38,
		nombre: 'Camerún',
		codigo: 'CM',
	},
	{
		id: 39,
		nombre: 'Canadá',
		codigo: 'CA',
	},
	{
		id: 40,
		nombre: 'Chad',
		codigo: 'TD',
	},
	{
		id: 41,
		nombre: 'Chile',
		codigo: 'CL',
	},
	{
		id: 42,
		nombre: 'China',
		codigo: 'CN',
	},
	{
		id: 43,
		nombre: 'Chipre',
		codigo: 'CY',
	},
	{
		id: 44,
		nombre: 'Chipre Turco',
	},
	{
		id: 45,
		nombre: 'Christmas Islands',
		codigo: 'CX',
	},
	{
		id: 46,
		nombre: 'Ciudad del Vaticano',
		codigo: 'VA',
	},
	{
		id: 47,
		nombre: 'Colombia',
		codigo: 'CO',
	},
	{
		id: 48,
		nombre: 'Comores',
		codigo: 'KM',
	},
	{
		id: 49,
		nombre: 'Corea del Norte',
		codigo: 'KP',
	},
	{
		id: 50,
		nombre: 'Corea del Sur',
		codigo: 'KR',
	},
	{
		id: 51,
		nombre: 'Costa de Marfil',
		codigo: 'CI',
	},
	{
		id: 52,
		nombre: 'Costa Rica',
		codigo: 'CR',
	},
	{
		id: 53,
		nombre: 'Croacia',
		codigo: 'HR',
	},
	{
		id: 54,
		nombre: 'Cuba',
		codigo: 'CU',
	},
	{
		id: 55,
		nombre: 'Dinamarca',
		codigo: 'DK',
	},
	{
		id: 56,
		nombre: 'Dominica',
		codigo: 'DM',
	},
	{
		id: 57,
		nombre: 'Ecuador',
		codigo: 'EC',
	},
	{
		id: 58,
		nombre: 'Egipto',
		codigo: 'EG',
	},
	{
		id: 59,
		nombre: 'El Salvador',
		codigo: 'SV',
	},
	{
		id: 60,
		nombre: 'Emiratos Arabes Unidos',
		codigo: 'AE',
	},
	{
		id: 61,
		nombre: 'Eritrea',
		codigo: 'ER',
	},
	{
		id: 62,
		nombre: 'Eslovaquia',
		codigo: 'SK',
	},
	{
		id: 63,
		nombre: 'Eslovenia',
		codigo: 'SI',
	},
	{
		id: 2,
		nombre: 'España',
		codigo: 'ES',
	},
	{
		id: 64,
		nombre: 'Estados Unidos',
		codigo: 'US',
	},
	{
		id: 65,
		nombre: 'Estonia',
		codigo: 'EE',
	},
	{
		id: 66,
		nombre: 'Etiopia',
		codigo: 'ET',
	},
	{
		id: 67,
		nombre: 'Federacion Rusa',
		codigo: 'RU',
	},
	{
		id: 68,
		nombre: 'Filipinas',
		codigo: 'PH',
	},
	{
		id: 69,
		nombre: 'Finlandia',
		codigo: 'FI',
	},
	{
		id: 70,
		nombre: 'Fiyi',
		codigo: 'FJ',
	},
	{
		id: 71,
		nombre: 'Francia',
		codigo: 'FR',
	},
	{
		id: 72,
		nombre: 'Gabón',
		codigo: 'GA',
	},
	{
		id: 73,
		nombre: 'Gambia',
		codigo: 'GM',
	},
	{
		id: 74,
		nombre: 'Georgia',
		codigo: 'GE',
	},
	{
		id: 75,
		nombre: 'Ghana',
		codigo: 'GH',
	},
	{
		id: 76,
		nombre: 'Gibraltar',
		codigo: 'GI',
	},
	{
		id: 77,
		nombre: 'Gran Bretaña',
		codigo: 'GBR',
	},
	{
		id: 78,
		nombre: 'Granada',
		codigo: 'GD',
	},
	{
		id: 79,
		nombre: 'Grecia',
		codigo: 'GR',
	},
	{
		id: 80,
		nombre: 'Groenlandia',
		codigo: 'GL',
	},
	{
		id: 81,
		nombre: 'Guadalupe',
		codigo: 'GP',
	},
	{
		id: 82,
		nombre: 'Guam',
		codigo: 'GU',
	},
	{
		id: 83,
		nombre: 'Guatemala',
		codigo: 'GT',
	},
	{
		id: 84,
		nombre: 'Guinea Ecuatorial',
		codigo: 'GQ',
	},
	{
		id: 85,
		nombre: 'Guinea Republic',
	},
	{
		id: 86,
		nombre: 'Guinea-Bissau',
		codigo: 'GW',
	},
	{
		id: 87,
		nombre: 'Guyana',
		codigo: 'GY',
	},
	{
		id: 88,
		nombre: 'Guyana Francesa',
		codigo: 'GF',
	},
	{
		id: 89,
		nombre: 'Haiti',
		codigo: 'HT',
	},
	{
		id: 90,
		nombre: 'Honduras',
		codigo: 'HN',
	},
	{
		id: 91,
		nombre: 'Hong Kong',
		codigo: 'HK',
	},
	{
		id: 92,
		nombre: 'Hungría',
		codigo: 'HU',
	},
	{
		id: 93,
		nombre: 'India',
		codigo: 'IN',
	},
	{
		id: 94,
		nombre: 'Indonesia',
		codigo: 'ID',
	},
	{
		id: 95,
		nombre: 'Iran',
		codigo: 'IR',
	},
	{
		id: 96,
		nombre: 'Iraq',
		codigo: 'IQ',
	},
	{
		id: 97,
		nombre: 'Irlanda',
		codigo: 'IE',
	},
	{
		id: 98,
		nombre: 'Islandia',
		codigo: 'IS',
	},
	{
		id: 99,
		nombre: 'Islas Cocos',
		codigo: 'CC',
	},
	{
		id: 100,
		nombre: 'Islas Cook',
		codigo: 'CK',
	},
	{
		id: 101,
		nombre: 'Islas de Cabo Verde',
		codigo: 'CV',
	},
	{
		id: 102,
		nombre: 'Islas Feroe',
		codigo: 'FO',
	},
	{
		id: 103,
		nombre: 'Islas Malvinas',
		codigo: 'FK',
	},
	{
		id: 104,
		nombre: 'Islas Marianas',
	},
	{
		id: 105,
		nombre: 'Islas Salomón',
		codigo: 'SB',
	},
	{
		id: 106,
		nombre: 'Islas Vírgenes Americanas',
		codigo: 'VI',
	},
	{
		id: 107,
		nombre: 'Islas Vírgenes Británicas',
		codigo: 'VG',
	},
	{
		id: 108,
		nombre: 'Israel',
		codigo: 'IL',
	},
	{
		id: 109,
		nombre: 'Italia',
		codigo: 'IT',
	},
	{
		id: 110,
		nombre: 'Jamaica',
		codigo: 'JM',
	},
	{
		id: 111,
		nombre: 'Japon',
		codigo: 'JP',
	},
	{
		id: 112,
		nombre: 'Jordania',
		codigo: 'JO',
	},
	{
		id: 113,
		nombre: 'Kazajstan',
		codigo: 'KZ',
	},
	{
		id: 114,
		nombre: 'Kenia',
		codigo: 'KE',
	},
	{
		id: 115,
		nombre: 'Kirguizistan',
		codigo: 'KG',
	},
	{
		id: 116,
		nombre: 'Kiribati',
		codigo: 'KI',
	},
	{
		id: 117,
		nombre: 'Kuwait',
		codigo: 'KW',
	},
	{
		id: 118,
		nombre: 'Laos',
		codigo: 'LA',
	},
	{
		id: 119,
		nombre: 'Las Bermudas',
		codigo: 'BM',
	},
	{
		id: 230,
		nombre: 'Latinoamérica y Caribe',
		codigo: 'Latam',
	},
	{
		id: 120,
		nombre: 'Leshoto',
		codigo: 'LS',
	},
	{
		id: 121,
		nombre: 'LEtonia',
		codigo: 'LV',
	},
	{
		id: 122,
		nombre: 'Líbano',
		codigo: 'LB',
	},
	{
		id: 123,
		nombre: 'Liberia',
		codigo: 'LR',
	},
	{
		id: 124,
		nombre: 'Libia',
		codigo: 'LY',
	},
	{
		id: 125,
		nombre: 'Liechtenstein',
		codigo: 'LI',
	},
	{
		id: 126,
		nombre: 'Lituania',
		codigo: 'LT',
	},
	{
		id: 127,
		nombre: 'Luxemburgo',
		codigo: 'LU',
	},
	{
		id: 128,
		nombre: 'Macao',
		codigo: 'MO',
	},
	{
		id: 129,
		nombre: 'Macedonia',
		codigo: 'MK',
	},
	{
		id: 130,
		nombre: 'Madagascar',
		codigo: 'MG',
	},
	{
		id: 131,
		nombre: 'Malasia',
		codigo: 'MY',
	},
	{
		id: 132,
		nombre: 'Malawi',
		codigo: 'MW',
	},
	{
		id: 133,
		nombre: 'Maldivas',
		codigo: 'MV',
	},
	{
		id: 134,
		nombre: 'Mali',
		codigo: 'ML',
	},
	{
		id: 135,
		nombre: 'Malta',
		codigo: 'MT',
	},
	{
		id: 136,
		nombre: 'Marruecos',
		codigo: 'MA',
	},
	{
		id: 137,
		nombre: 'Marshall Islands',
		codigo: 'MH',
	},
	{
		id: 138,
		nombre: 'Martinica',
		codigo: 'MQ',
	},
	{
		id: 139,
		nombre: 'Mauricio',
		codigo: 'MU',
	},
	{
		id: 140,
		nombre: 'Mauritania',
		codigo: 'MR',
	},
	{
		id: 141,
		nombre: 'Mayotte',
		codigo: 'YT',
	},
	{
		id: 142,
		nombre: 'Mexico',
		codigo: 'MX',
	},
	{
		id: 143,
		nombre: 'Micronesia',
		codigo: 'FM',
	},
	{
		id: 144,
		nombre: 'Moldavia',
		codigo: 'MD',
	},
	{
		id: 145,
		nombre: 'Mónaco',
		codigo: 'MC',
	},
	{
		id: 146,
		nombre: 'Mongolia',
		codigo: 'MN',
	},
	{
		id: 147,
		nombre: 'Montserrat',
		codigo: 'MS',
	},
	{
		id: 148,
		nombre: 'Mozambique',
		codigo: 'MZ',
	},
	{
		id: 231,
		nombre: 'Mundial',
		codigo: 'Mun',
	},
	{
		id: 149,
		nombre: 'Myanmar',
		codigo: 'MM',
	},
	{
		id: 150,
		nombre: 'Namibia',
		codigo: 'NA',
	},
	{
		id: 151,
		nombre: 'Nauru',
		codigo: 'NR',
	},
	{
		id: 152,
		nombre: 'Nepal',
		codigo: 'NP',
	},
	{
		id: 153,
		nombre: 'Nicaragua',
		codigo: 'NI',
	},
	{
		id: 154,
		nombre: 'Niger',
		codigo: 'NE',
	},
	{
		id: 155,
		nombre: 'Nigeria',
		codigo: 'NG',
	},
	{
		id: 156,
		nombre: 'Niue',
		codigo: 'NU',
	},
	{
		id: 157,
		nombre: 'Norfolk Islands',
		codigo: 'NF',
	},
	{
		id: 158,
		nombre: 'Noruega',
		codigo: 'NO',
	},
	{
		id: 159,
		nombre: 'Nueva Caledonia',
		codigo: 'NC',
	},
	{
		id: 160,
		nombre: 'Nueva Zelanda',
		codigo: 'NZ',
	},
	{
		id: 161,
		nombre: 'Omán',
		codigo: 'OM',
	},
	{
		id: 3,
		nombre: 'otro',
	},
	{
		id: 162,
		nombre: 'Paises Bajos',
		codigo: 'NL',
	},
	{
		id: 163,
		nombre: 'Pakistán',
		codigo: 'PK',
	},
	{
		id: 164,
		nombre: 'Palau',
	},
	{
		id: 165,
		nombre: 'Panama',
		codigo: 'PA',
	},
	{
		id: 166,
		nombre: 'Papúa Nueva Guinea',
		codigo: 'PG',
	},
	{
		id: 167,
		nombre: 'Paraguay',
		codigo: 'PY',
	},
	{
		id: 168,
		nombre: 'Peru',
		codigo: 'PE',
	},
	{
		id: 169,
		nombre: 'Pitcairn Islands',
		codigo: 'PN',
	},
	{
		id: 170,
		nombre: 'Polinesia Francesa',
		codigo: 'PF',
	},
	{
		id: 171,
		nombre: 'Polonia',
		codigo: 'PL',
	},
	{
		id: 172,
		nombre: 'Portugal',
		codigo: 'PT',
	},
	{
		id: 173,
		nombre: 'Puerto Rico',
		codigo: 'PR',
	},
	{
		id: 174,
		nombre: 'Qatar',
		codigo: 'QA',
	},
	{
		id: 175,
		nombre: 'Republica Centroafricana',
		codigo: 'CF',
	},
	{
		id: 176,
		nombre: 'Republica Checa',
		codigo: 'CZ',
	},
	{
		id: 177,
		nombre: 'República del Congo (Brazzaville)',
	},
	{
		id: 178,
		nombre: 'República Democrática del Congo (Kinshasa)',
	},
	{
		id: 179,
		nombre: 'República Dominicana',
		codigo: 'DO',
	},
	{
		id: 180,
		nombre: 'Reunión',
		codigo: 'RE',
	},
	{
		id: 181,
		nombre: 'Ruanda',
		codigo: 'RW',
	},
	{
		id: 182,
		nombre: 'Rumania',
		codigo: 'RO',
	},
	{
		id: 183,
		nombre: 'Samoa Americana',
		codigo: 'AS',
	},
	{
		id: 184,
		nombre: 'San Cristobal y Nevis',
		codigo: 'KN',
	},
	{
		id: 185,
		nombre: 'San Marino',
		codigo: 'SM',
	},
	{
		id: 186,
		nombre: 'San Pedro y Miquelón',
		codigo: 'PM',
	},
	{
		id: 187,
		nombre: 'San Tomé y Príncipe',
		codigo: 'ST',
	},
	{
		id: 188,
		nombre: 'San Vicente & Granadinas',
		codigo: 'VC',
	},
	{
		id: 189,
		nombre: 'Santa Elena',
		codigo: 'SH',
	},
	{
		id: 190,
		nombre: 'Santa Lucía',
		codigo: 'LC',
	},
	{
		id: 191,
		nombre: 'Senegal',
		codigo: 'SN',
	},
	{
		id: 192,
		nombre: 'Serbia y Montenegro',
		codigo: 'RS',
	},
	{
		id: 193,
		nombre: 'Seychelles',
		codigo: 'SC',
	},
	{
		id: 194,
		nombre: 'Sierra Leona',
		codigo: 'SL',
	},
	{
		id: 195,
		nombre: 'Singapur',
		codigo: 'SG',
	},
	{
		id: 196,
		nombre: 'Siria',
		codigo: 'SY',
	},
	{
		id: 197,
		nombre: 'Somalia',
		codigo: 'SO',
	},
	{
		id: 198,
		nombre: 'Sri Lanka',
		codigo: 'LK',
	},
	{
		id: 199,
		nombre: 'Sudafrica',
		codigo: 'ZA',
	},
	{
		id: 200,
		nombre: 'Sudán',
		codigo: 'SD',
	},
	{
		id: 201,
		nombre: 'Suecia',
		codigo: 'SE',
	},
	{
		id: 202,
		nombre: 'Suiza',
		codigo: 'CH',
	},
	{
		id: 203,
		nombre: 'Surinam',
		codigo: 'SR',
	},
	{
		id: 204,
		nombre: 'Swazilandia',
	},
	{
		id: 205,
		nombre: 'Tadjikistan',
		codigo: 'TJ',
	},
	{
		id: 206,
		nombre: 'Tailandia',
		codigo: 'TH',
	},
	{
		id: 207,
		nombre: 'Taiwán',
		codigo: 'TW',
	},
	{
		id: 208,
		nombre: 'Tanzania',
		codigo: 'TH',
	},
	{
		id: 209,
		nombre: 'Timor Oriental',
		codigo: 'TL',
	},
	{
		id: 210,
		nombre: 'Togo',
		codigo: 'TG',
	},
	{
		id: 211,
		nombre: 'Tonga',
		codigo: 'TO',
	},
	{
		id: 212,
		nombre: 'Trinidad y Tobago',
		codigo: 'TT',
	},
	{
		id: 213,
		nombre: 'Túnez',
		codigo: 'TN',
	},
	{
		id: 214,
		nombre: 'Turkmenistán',
		codigo: 'TM',
	},
	{
		id: 215,
		nombre: 'Turks y Caicos',
		codigo: 'TC',
	},
	{
		id: 216,
		nombre: 'Turquía',
		codigo: 'TR',
	},
	{
		id: 217,
		nombre: 'Tuvalu',
		codigo: 'TV',
	},
	{
		id: 218,
		nombre: 'Ucrania',
		codigo: 'UA',
	},
	{
		id: 219,
		nombre: 'Uganda',
		codigo: 'UG',
	},
	{
		id: 220,
		nombre: 'Uruguay',
		codigo: 'UY',
	},
	{
		id: 221,
		nombre: 'Uzbekistán',
		codigo: 'UZ',
	},
	{
		id: 222,
		nombre: 'Vanuatu',
		codigo: 'VU',
	},
	{
		id: 223,
		nombre: 'Venezuela',
		codigo: 'VE',
	},
	{
		id: 224,
		nombre: 'Vietnam',
		codigo: 'VN',
	},
	{
		id: 225,
		nombre: 'Wallis y Futuna',
		codigo: 'WF',
	},
	{
		id: 226,
		nombre: 'Yemen',
		codigo: 'YE',
	},
	{
		id: 227,
		nombre: 'Yibuti',
		codigo: 'DJ',
	},
	{
		id: 228,
		nombre: 'Zambia',
	},
	{
		id: 229,
		nombre: 'Zimbabwe',
	},
];

//'cerrado','prescrito','pagado','resolucion_favorable_asegurado','resolucion_desfavorable_asegurado'

export const tipos_de_monedas = [
	{ label: 'AR$', value: 'ars' },
	{ label: 'U$S', value: 'usd' },
	{ label: '€', value: 'eur' },
	{ label: 'UYU$', value: 'uyu' },
	{ label: 'CLP$', value: 'clp' },
	{ label: 'QUINTAL', value: 'quintal' },
	{ label: 'UF', value: 'uf' },
];

export const tipos_refacturacion = [
	{
		id: 'mensual',
		value: 'Mensual',
	},
	{
		id: 'bimestral',
		value: 'Bimestral',
	},
	{
		id: 'trimestral',
		value: 'Trimestral',
	},
	{
		id: 'cuatrimestral',
		value: 'Cuatrimestral',
	},
	{
		id: 'semestral',
		value: 'Semestral',
	},
	{
		id: 'anual',
		value: 'Anual',
	},
];

export const tipos_origen_poliza = [
	{
		id: 'presencial',
		value: 'Presencial',
	},
	{
		id: 'telefonica',
		value: 'Telefónica',
	},
	{
		id: 'email',
		value: 'Mail',
	},
	{
		id: 'internet',
		value: 'Internet',
	},
];

export const tipos_renovacion_poliza = [
	{
		id: 'automatica',
		value: 'Automática',
	},
	{
		id: 'manual',
		value: 'Manual',
	},
	{
		id: 'no_renovable',
		value: 'No renovable',
	},
];

export const tipos_poliza = [
	{
		id: 'local',
		value: 'Local (normal)',
	},
	{
		id: 'programa_mundial',
		value: 'Programa mundial',
	},
	{
		id: 'facultativa',
		value: 'Facultativa',
	},
    {
		id: 'fronting',
		value: 'Fronting',
	},
];

export const tipos_de_pagos = [
	{ value: 'cheque', label: 'Cheque' },
	{ value: 'debito', label: 'Débito' },
	{ value: 'deposito', label: 'Depósito Bancario' },
	{ value: 'efectivo', label: 'Efectivo' },
	{ value: 'pago_facil', label: 'Pago Fácil / Rapipago' },
	{ value: 'transferencia', label: 'Transferencia' },
	{ value: 'afip', label: 'Afip' },
	{ value: 'debito_cuenta', label: 'Débito en Cuenta' },
	{ value: 'tarjeta_credito', label: 'Tarjeta de Crédito' },
    { value: 'mercado_pago', label: 'Mercado Pago' },
    { value: 'Pago_centralizado', label: 'Pago Centralizado' },
];

export const getSymbol = (value) => {
	return tipos_de_monedas.find((moneda) => moneda.value === value)?.label;
};

export const getPayType = (value) => {
	return tipos_de_pagos.find((tipo) => value === tipo.value)?.label;
};

export const tipos_lesion_vida = [
	{ id: 'Muerte', value: 'Muerte' },
	{ id: 'Invalidez', value: 'Invalidez' },
	{ id: 'Enfermedad', value: 'Enfermedad' },
];
export const tipos_documental_vida = [
	{ id: 'Completa', value: 'Completa' },
	{ id: 'Adeuda', value: 'Adeuda' },
];

export const tipos_endosos = [
	{ id: 'alta_adicionales', value: 'Alta de adicionales' },
	{ id: 'alta_asegurado', value: 'Alta asegurado' },
	{ id: 'alta_bien', value: 'Alta de bien' },
	{ id: 'anulacion', value: 'Anulacion' },
	{ id: 'anulacion_cia', value: 'Anulacion por decision de la compañia' },
	{ id: 'anulacion_de_endoso', value: 'Anulacion de endoso' },
	{ id: 'anulacion_falta_pago', value: 'Anulacion por falta de pago' },
	{ id: 'anulacion_reeamplazo', value: 'Anulacion por reemplazo' },
	{ id: 'anulacion_siniestro', value: 'Anulacion por siniestro' },
	{ id: 'anulacion_venta', value: 'Anulacion por venta' },
	{ id: 'anulacion_liberacion', value: 'Anulacion por liberacion' },
	{ id: 'anulacion_desistimiento', value: 'Anulacion por desistimiento' },
	{ id: 'anulacion_cambio_cia', value: 'Anulacion por cambio de compañia' },
	{ id: 'aumento_suma_asegurada', value: 'Aumento de la suma asegurada' },
	{ id: 'disminucion_suma_asegurada', value: 'Disminución de la suma asegurada' },
	{ id: 'baja_adicionales', value: 'Baja de adicionales' },
	{ id: 'baja_asegurado', value: 'Baja asegurado' },
	{ id: 'baja_bien', value: 'Baja de bien' },
	{ id: 'cambio_asegurado', value: 'Cambio de asegurado' },
	{ id: 'cambio_bien', value: 'Cambio de bien' },
	{ id: 'cambio_tomador', value: 'Cambio de tomador' },
	{ id: 'corte_mensual', value: 'Corte mensual' },
	{ id: 'devolucion', value: 'Devolucion' },
	{ id: 'modifica_datos_bien', value: 'Modifica datos del bien' },
	{ id: 'modificacion_accesorios', value: 'Modificacion de los accesorios' },
	{ id: 'modificacion_clausula_ajuste', value: 'Modificacion de la clausula de ajuste' },
	{ id: 'modificacion_cobertura', value: 'Modificacion de cobertura' },
	{ id: 'modificacion_comision', value: 'Modificacion de la comision' },
	{ id: 'modificacion_datos', value: 'Modificacion de datos' },
	{ id: 'modificacion_moneda', value: 'Modificacion de la moneda' },
	{ id: 'modificacion_premio', value: 'Modificacion del premio' },
	{ id: 'modificacion_prima', value: 'Modificacion de la prima' },
	{ id: 'modificacion_suma_asegurada', value: 'Modificacion de la suma asegurada' },
	{ id: 'modificacion_titularidad', value: 'Modificacion titularidad' },
	{ id: 'modificacion_ubicacion_bien', value: 'Modificacion de ubicacion del bien' },
	{ id: 'modificacion_vigencia', value: 'Modificacion de la vigencia' },
	{ id: 'nota_credito', value: 'Nota credito' },
	{ id: 'otros', value: 'Otros' },
	{ id: 'recuotificacion', value: 'Recuotificacion' },
	{ id: 'refacturacion', value: 'Refacturacion' },
	{ id: 'rehabilitacion', value: 'Rehabilitacion' },
	{ id: 'viaje_en_exceso', value: 'Viaje en exceso' },
	{ id: 'aclaracion_texto', value: 'Aclaracion/Modificacion de texto' },
    { id: 'anulacion_liberacion', value: 'Anulacion por liberacion' },
    { id: 'anulacion_desistimiento', value: 'Anulacion por desistimiento' },
    { id: 'anulacion_cambio_cia', value: 'Anulacion por cambio de compañia' },
    { id: 'aumento_suma_asegurada', value: 'Aumento de la suma asegurada' },
    { id: 'disminucion_suma_asegurada', value: 'Disminucion de la suma asegurada' },
    { id: 'alta_baja_riesgo', value: 'Inclusion/Exclusion de riesgo' },
    { id: 'carta_nombramiento', value: 'Carta de nombramiento' },
    { id: 'rectificacion', value: 'Rectificación' },
    { id: 'refacturacion_duplicado', value: 'Refacturacion Duplicado' },
    { id: 'modificacion_via_cobro', value: 'Modificacion de via de cobro' },
    { id: 'aumento_alicuota', value: 'Aumento de alicuota' },
];

export const tipos_caucion = [
	{ id: 'administrador_sociedades', value: 'Administrador de Sociedades', vigencia_abierta: true },
	{ id: 'corredor_inmobiliario', value: 'Corredor Inmobiliario', vigencia_abierta: true },
	{ id: 'garantia_aduanera', value: 'Garantía Aduanera', vigencia_abierta: true },
	{ id: 'acopios', value: 'Acopios', vigencia_abierta: false },
	{ id: 'aduana_domiciliaria', value: 'Aduana domiciliaria', vigencia_abierta: false },
	{ id: 'alquiler', value: 'Alquiler', vigencia_abierta: true },
	{ id: 'anticipos', value: 'Anticipos', vigencia_abierta: false },
	{ id: 'diferencia_de_derechos', value: 'Diferencia de Derechos', vigencia_abierta: false },
	{ id: 'diferimiento_impositivo', value: 'Diferimiento impositivo', vigencia_abierta: false },
	{ id: 'ejecucion_de_contrato', value: 'Ejecución de Contrato', vigencia_abierta: true },
	{ id: 'envios_en_consignacion', value: 'Envíos en consignación', vigencia_abierta: false },
	{ id: 'exportacion_temporaria', value: 'Exportación Temporaria', vigencia_abierta: false },
	{ id: 'falta_de_documentacion', value: 'Falta de Documentación', vigencia_abierta: false },
	{
		id: 'garantia_de_inscripcion_para_importadores_y_exportadores',
		value: 'Garantía de inscripción para importadores y exportadores',
		vigencia_abierta: true,
	},
	{ id: 'garantias_globales', value: 'Garantías globales', vigencia_abierta: false },
	{ id: 'habilitacion_de_deposito_fiscal', value: 'Habilitación de Depósito Fiscal', vigencia_abierta: true },
	{ id: 'importacion_temporaria', value: 'Importación Temporaria', vigencia_abierta: false },
	{ id: 'judiciales_cautela', value: 'Judiciales - Cautela', vigencia_abierta: false },
	{ id: 'judiciales_contracautela', value: 'Judiciales - Contracautela', vigencia_abierta: false },
	{ id: 'mantenimiento_de_oferta', value: 'Mantenimiento de Oferta', vigencia_abierta: true },
	{ id: 'seguro_ambiental_obligatorio', value: 'Seguro Ambiental Obligatorio', vigencia_abierta: false },
	{ id: 'sustitucion_de_fondo_de_reparo', value: 'Sustitución de Fondo de Reparo', vigencia_abierta: false },
	{ id: 'transito_terrestre', value: 'Tránsito Terrestre', vigencia_abierta: true },
	{ id: 'actividad_o_profesion', value: 'Desempeño Actividad/profesion', vigencia_abierta: true },
	{ id: 'concesiones', value: 'Concesiones', vigencia_abierta: true },
    { id: 'garantia_adjudicacion', value: 'Garantía de adjudicación', vigencia_abierta: false },
];

export const estados_reporte = [
    { value: 'finished', label: 'Terminado' },
    { value: 'running', label: 'Ejecutando' },
    { value: 'created', label: 'Esperando ejecución' },
    { value: 'error', label: 'Error' },
    { value: 'too_many_registers', label: 'Error - Demasiados registros solicitados' },
];

export const tipos_reporte = [
    { value: 'people', label: 'Clientes / Prospectos' },
    { value: 'vehicles', label: 'Unidades / Vehículos' },
    { value: 'policies', label: 'Pólizas' },
    { value: 'operations', label: 'Operaciones' },
    { value: 'policies_map', label: 'Mapa de pólizas' },
    { value: 'company_payment_concepts', label: 'Pagos en compañía (pendientes)' },
    { value: 'client_payment_concepts', label: 'Pagos de clientes (pendientes)' },
    { value: 'company_payments', label: 'Pagos en compañía (realizados)' },
    { value: 'commission_payments', label: 'Comisiones generadas' },
    { value: 'risk_person_map', label: 'Mapa de ramos contratados' },
    { value: 'people_risk_map', label: 'Mapa de ramos contratados' },
    { value: 'policies_commissions_schema', label: 'Esquema de comisiones' },
]

export const tipos_documentos_default = [
    { external_id: 'download_policy', denominacion: 'Póliza' },
    { external_id: 'download_frente', denominacion: 'Frente de Póliza' },
    { external_id: 'download_mercosur', denominacion: 'Certificado Mercosur' },
    { external_id: 'download_circulation_card', denominacion: 'Tarjeta de Circulación' },
    { external_id: 'download_payment_coupon', denominacion: 'Cupón de Pago' },
];

export const translateRecipientType = (type) => {
    switch (type) {
        case 'Employee': return 'Empleado';
        case 'Producer': return 'Productor';
        case 'Organization': return 'Organización';
        default: return type;
    }
}

export const getElementByValue = (value, listName) => {
    const list = {
        tipos_uso_automotor,
        tipos_carroceria_automotor,
        tipos_vehiculo,
        tipos_origen_vehiculo,
        tipos_documento,
        car_logos,
        tipos_operacion,
        tipos_banco,
        tipos_tarjeta,
        tipos_profesion,
        tipos_estado_civil,
        tipos_direccion,
        paises,
        tipos_iva,
        tipos_persona,
        tipos_sexo,
        tipos_telefono,
        tipos_mail,
        tomador_asegurado_options,
        potencial_captivo_options,
        tipo_estado_cir,
        tipos_accion,
        tipos_entidad,
        tipo_origen_cheque,
        tipo_estado_cheque,
        tipo_seguimiento,
        usuario_o_externo,
        cliente_poliza_o_siniestro,
        tipos_estado_poliza,
        tipos_estado_poliza_simplificados,
        tipos_despacho_poliza,
        tipo_estado_siniestro,
        tipo_motivo_siniestro,
        tipo_activos_reportes_unidades,
        paises_viajes,
        tipos_de_monedas,
        tipos_refacturacion,
        tipos_origen_poliza,
        tipos_renovacion_poliza,
        tipos_poliza,
        tipos_de_pagos,
        tipos_lesion_vida,
        tipos_documental_vida,
        tipos_endosos,
        tipos_caucion,
        estados_reporte,
        tipos_reporte,
    }[listName];

    return list?.find(el => el.value === value || el.id === value);
};

// eval is evil
/*
export const getElementByValue = (value, list) => {
	return eval(list)?.find((el) => el.value === value);
};
*/
