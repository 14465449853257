<script setup>
import { ref, onMounted, computed } from 'vue'
import { saveErrorReport } from '@service/import/ErrorReportService';
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { loadProducerCodesByCompanyCodigoAndProducer } from '@service/ProducerCodeService'
import AutocompleteProducerCode from '@components/shared/AutocompleteProducerCode.vue';
import DropdownProducerCodes from "@components/shared/DropdownProducerCodes.vue"
import AutocompleteRamos from '@components/shared/AutocompleteRamos.vue'
import { pageLoading } from '@/main';

const props = defineProps({
    selectedImport: Object,
})

const errorReportForm = defineModel('errorReportForm', { 
    default: () => ({
        producer_operation_code: null,
        client: '',
        numero_poliza: '',
        ramo: null,
        fecha_emision: null,
        fecha_vigencia_desde: null,
        observaciones: '',
        company_id: null,
        import_id: null,
        producer_operation_code_id: null
    })
});

const emit = defineEmits(['error-report-sent'])

const producerCodes = ref([])

onMounted(async() => {
    producerCodes.value = await loadProducerCodesByCompanyCodigoAndProducer(props.selectedImport.producer, props.selectedImport.company.codigo)
})

const rules = computed(() => ({
    numero_poliza: { requiredif: requiredIf(props.selectedImport.status !='error') },
    client: { requiredif: requiredIf(props.selectedImport.status !='error') },
    observaciones: { required },
    producer_operation_code_id: { required },
    ramo: { requiredif: requiredIf(props.selectedImport.status !='error') },
    fecha_emision: { requiredif: requiredIf(props.selectedImport.status !='error') },
    fecha_vigencia_desde: { requiredif: requiredIf(props.selectedImport.status !='error') },
}) )

const v$ = useVuelidate(rules, ref(errorReportForm) )

async function sendErrorReportForm() {
    errorReportForm.value.company_id = props.selectedImport.company.id
    errorReportForm.value.import_id = props.selectedImport.id

    errorReportForm.value.producer_operation_code_id =  errorReportForm.value.producer_operation_code?.id

    const isFormValid = await v$.value.$validate()
    
    if (isFormValid){
        pageLoading.value = true;
        saveErrorReport(errorReportForm.value).then((response) => {
            emit('error-report-sent', errorReportForm.value)
            errorReportForm.value = {
                producer_operation_code: null,
                client: '',
                numero_poliza: '',
                ramo: null,
                fecha_emision: null,
                fecha_vigencia_desde: null,
                observaciones: '',
                company_id: null,
                import_id: null,
                producer_operation_code_id: null
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            pageLoading.value = false;
        });
    }
}

</script>

<template>
    <form @submit.prevent="sendErrorReportForm()">
        <div class="p-fluid grid pt-5 w-full mb-5">
            <div class="field col-6">
                <span class="p-float-label">
                    <AutocompleteProducerCode
                        label="Codigo"
                        v-model="errorReportForm.producer_operation_code"
                        :extraFilters="{ 
                            company_id: { property: 'company_id', type: 'combo', value: selectedImport.company.id }
                        }"
                    />
                    <!--DropdownProducerCodes v-bind:class="(v$.producer_operation_code_id.$error) ? 'p-invalid' : ''" class="min-w-full" label="Código"
                        :producer_codes="producerCodes"
                        v-model="errorReportForm.producer_operation_code_id">
                    </DropdownProducerCodes>
                    <label>Código</label-->
                </span>
            </div>
            <div class="field col-6" v-if="selectedImport.status !='error'">
                <span class="p-float-label">
                    <InputText id="client" v-bind:class="(v$.client.$error) ? 'p-invalid' : ''"
                        v-model="errorReportForm.client" />
                    <label for="client">
                        Cliente
                    </label>
                </span>
            </div>
            <div class="field col-6" v-if="selectedImport.status !='error'">
                <span class="p-float-label">
                    <InputText id="numero_poliza" v-bind:class="(v$.numero_poliza.$error) ? 'p-invalid' : ''"
                    v-model="errorReportForm.numero_poliza" />
                    <label for="numero_poliza">
                        Póliza / Endoso faltante
                    </label>
                </span>
            </div>
            <div class="field col-6" v-if="selectedImport.status !='error'">
                <span class="p-float-label">
                    <AutocompleteRamos  v-bind:class="(v$.ramo.$error) ? 'p-invalid' : ''"
                        v-model="errorReportForm.ramo" :multiple="false" label="Ramo" />
                </span>
            </div>
            <div class="field col-6" v-if="selectedImport.status !='error'">
                <span class="p-float-label">
                    <Calendar class="w-12" v-bind:class="(v$.fecha_emision.$error) ? 'p-invalid' : ''" id="fecha" v-model="errorReportForm.fecha_emision"
                        showButtonBar />
                    <label for="fecha">Fecha Emisión</label>
                </span>
            </div>
            <div class="field col-6" v-if="selectedImport.status !='error'">
                <span class="p-float-label">
                    <Calendar class="w-12" v-bind:class="(v$.fecha_vigencia_desde.$error) ? 'p-invalid' : ''" id="fecha" v-model="errorReportForm.fecha_vigencia_desde"
                        showButtonBar />
                    <label for="fecha">Fecha Vigencia Desde</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <Textarea rows="5" cols="50" autoResize id="observaciones" v-bind:class="(v$.observaciones.$error) ? 'p-invalid' : ''"
                    v-model="errorReportForm.observaciones" />
                    <label for="observaciones">
                        Observaciones
                    </label>
                </span>
            </div>
        </div>
        <div class="p-fluid grid">
            <div class="field col-12 md:col-10"></div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <Button type="submit" label="Enviar" />
                </span>
            </div>
        </div>
    </form>
</template>
<style scoped lang="scss">
</style>